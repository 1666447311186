import { useWeb3React } from '@web3-react/core'
import { useChainId } from 'metronome-ui/hooks/chains'
import { useContext, useEffect, useState } from 'react'

import WalletContext from '../components/WalletContext'

import { useWalletChainId } from './useWalletChainId'

const useTransactionOptions = function () {
  const { wallet } = useContext(WalletContext)
  const chainId = useChainId()
  const walletChainId = useWalletChainId(false)
  const [useEip1559, setUseEip1559] = useState(false)
  const [infiniteApprovalChecked, setInfiniteApprovalChecked] = useState(false)
  const [withdrawExact, setWithdrawExact] = useState(false)
  const [claimOnOperation, setClaimOnOperation] = useState(false)
  const [operateWithWrappedAsset, setOperateWithWrappedAsset] = useState(false)

  const [approvalChecked, setApprovalChecked] = useState()

  const { connector: currentConnector } = useWeb3React()

  useEffect(
    function () {
      setUseEip1559(function () {
        const supportedChains = [1, 10, 137]
        // return false if we're in dev env using ganache local fork
        if (walletChainId === 1337) {
          return false
        }

        return supportedChains.includes(chainId) &&
          wallet?.connectorName === 'injected'
          ? true
          : useEip1559
      })
    },
    [
      chainId,
      currentConnector,
      useEip1559,
      wallet?.connectorName,
      walletChainId
    ]
  )

  const resetTransactionOptionsValues = function () {
    setUseEip1559(false)
    setInfiniteApprovalChecked(false)
    setWithdrawExact(false)
    setClaimOnOperation(false)
    setOperateWithWrappedAsset(false)
  }

  return {
    options: {
      approvalChecked,
      claimOnOperation,
      infiniteApprovalChecked,
      onClaimOnOperationChange: setClaimOnOperation,
      onUseEip1559Change: setUseEip1559,
      onWithdrawExactChange: setWithdrawExact,
      operateWithWrappedAsset,
      setApprovalChecked,
      setInfiniteApprovalChecked,
      setOperateWithWrappedAsset,
      useEip1559,
      withdrawExact
    },
    resetTransactionOptionsValues
  }
}

export default useTransactionOptions
